import { Button, Menu, MenuItem, Stack } from "@mui/material";
import styled from "styled-components";
import { ConfirmStatus } from "./InnermapOverview.types";

interface ActionButtonProps {
  bg: 'green' | 'blue' | 'red'
}

interface MenuItemProps {
  type: ConfirmStatus
}

export const ActionButton = styled(Button) <ActionButtonProps>`
  text-transform: none;
  font-size: 16px;
  font-weight: 400;
  height: 36px;
  padding: 8.5px 12px;
  border: 1px solid transparent;
  background-color: ${props => props.bg === 'green' && props.theme.palette.legends.green + '26'};
  background-color: ${props => props.bg === 'blue' && props.theme.palette.bg.lightBlue};
  background-color: ${props => props.bg === 'red' && props.theme.palette.legends.red + '26'};
  color: ${props => props.bg === 'green' && props.theme.palette.legends.chartsGreen};
  color: ${props => props.bg === 'blue' && props.theme.palette.primary.main};
  color: ${props => props.bg === 'red' && props.theme.palette.error.main};

  &:hover {
    background-color: transparent;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), inset 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    border-color: ${props => props.bg === 'green' && props.theme.palette.legends.chartsGreen};
    border-color: ${props => props.bg === 'blue' && props.theme.palette.primary.main};
    border-color: ${props => props.bg === 'red' && props.theme.palette.error.main};
  }
`
export const SubmitButton = styled(Button)`
  text-transform: none;
  font-size: 16px;
  font-weight: 400;
  height: 36px;
  padding: 8.5px 12px;
`

export const StyledMenu = styled(Menu)`
  & > .MuiPaper-root {
    width: 173px;
  }
`
export const StyledMenuItem = styled(MenuItem)<MenuItemProps>`
  &:hover {
    color: ${props => props.type === 'agreed' && props.theme.palette.legends.chartsGreen};
    color: ${props => props.type === 'rejected' && props.theme.palette.error.main};
    background-color: ${props => props.type === 'agreed' && props.theme.palette.legends.green + '26'};
    background-color: ${props =>props.type === 'rejected' && props.theme.palette.legends.red + '26'};
  }
`