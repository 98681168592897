import { Box, Stack } from "@mui/material"
import { ActionButton, ConfirmStatus, InnermapOverviewProps, StyledMenu, StyledMenuItem, SubmitButton } from "."
import RdIcon from "@assets/icons/RdIcon"
import AddCommentIcon from '@mui/icons-material/AddComment';
import CheckIcon from '@mui/icons-material/Check';
import { useState } from "react"
import useConfirmDialog, { UseExitConfirmProps } from "@hooks/useConfirmDialog"
import { InnermapActionsType } from "@api/internalAgreement/types";
import Tooltip from "@components/Tooltip";
import { useActionAgreementOverviewMutation, useCompleteAgreementOverviewStageMutation } from "@api/agreementOverview";
import { useNavigate, useParams } from "react-router-dom";
import { useMutationHandlers } from "@hooks/useMutationHandlers";
import { useSnackbar } from "notistack";

export const InnermapOverview = ({ innermap, currentStageNumber, buttonAvailable }: InnermapOverviewProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const { projectId, tomId } = useParams()
  const id = Number(projectId)
  const { id: innermapId, member, status } = innermap || {}
  const disabled = status === 'agreed' || status === 'rejected'
  const approval = member.actions.find(a => a.action === 'approval')
  const assurance = member.actions.find(a => a.action === 'assurance')
  const signing = member.actions.find(a => a.action === 'signing')
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [confirmTrigger, setConfirmTrigger] = useState<ConfirmStatus>('agreed')

  const [actionAgreementOverview] = useActionAgreementOverviewMutation()
  const [completeAgreementOverviewStage, completeAgreementOverviewStageResponse] = useCompleteAgreementOverviewStageMutation()

  const onActionButtonClick = (action: InnermapActionsType) => {
    actionAgreementOverview({
      action,
      id,
      innermapId,
      memberId: member.id
    })
  }

  const navigateToRemarks = () => {
    navigate(`/project/${projectId}/tom/${tomId}?tab=remarks&remark=create`)
  }

  const handleSelect = (status: ConfirmStatus) => {
    setConfirmTrigger(status)
    openConfirm()
  }

  const onConfirmPositiveStatus = (confirm: boolean) => {
    if (confirm) {
      completeAgreementOverviewStage({
        status: 'agreed',
        id,
        innermapId,
        memberId: member.id
      })
    } else {
      setAnchorEl(null)
    }
  }

  const onConfirmNegativeStatus = (confirm: boolean) => {
    if (confirm) {
      completeAgreementOverviewStage({
        status: 'rejected',
        id,
        innermapId,
        memberId: member.id
      })
    } else {
      setAnchorEl(null)
    }
    setConfirmTrigger('agreed')
  }

  const dataForConfirmDialog: UseExitConfirmProps =
    confirmTrigger === 'agreed' ? {
      handleConfirm: onConfirmPositiveStatus,
      title: 'Подтвердить согласование тома?',
      body: `Этап ${currentStageNumber} будет завершен с результатом "Согласовано"`
    }
      : {
        handleConfirm: onConfirmNegativeStatus,
        title: 'Подтвердить отклонение тома?',
        body: `Этап ${currentStageNumber} будет завершен с результатом "Отклонено"`
      }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  useMutationHandlers(
    completeAgreementOverviewStageResponse,
    (data) => {
      if (data.status === 'agreed') enqueueSnackbar(`Том успешно согласован.`, { variant: 'success' })
      if (data.status === 'rejected') enqueueSnackbar(`Том успешно отклонен.`, { variant: 'success' })
      setAnchorEl(null)
    },
    (error) => {
      if (error) enqueueSnackbar(`При завершении этапа произошла ошибка`, { variant: 'error' })
    })

  return (
    <>
      <Stack direction='row' spacing={1}>
        {approval &&
          <Tooltip title={approval.datetime ? approval.datetime : ''}>
            <Box>
              <ActionButton disableRipple={false} bg='green' disabled={!!approval.datetime} onClick={() => onActionButtonClick('approval')} startIcon={
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.6663 1.66675H4.99967C4.08301 1.66675 3.34134 2.41675 3.34134 3.33341L3.33301 16.6667C3.33301 17.5834 4.07467 18.3334 4.99134 18.3334H14.9997C15.9163 18.3334 16.6663 17.5834 16.6663 16.6667V6.66675L11.6663 1.66675ZM14.9997 16.6667H4.99967V3.33341H10.833V7.50008H14.9997V16.6667Z" fill="CurrentColor" />
                  <path d="M9.43301 12.3751L8.25801 11.1917L7.08301 12.3667L9.43301 14.7334L13.258 10.9167L12.0747 9.7334L9.43301 12.3751Z" fill="CurrentColor" />
                </svg>
              }>
                Утвердить
              </ActionButton>
            </Box>
          </Tooltip>
        }

        {signing &&
          <Tooltip title={signing.datetime ? signing.datetime : ''}>
            <Box>
              <ActionButton disableRipple={false} bg='blue' disabled={!!signing.datetime} onClick={() => onActionButtonClick('signing')}
                startIcon={<RdIcon fontSize='medium' color={!!signing.datetime ? 'disabled' : 'primary'} />}>
                Подписать
              </ActionButton>
            </Box>
          </Tooltip>
        }

        {assurance &&
          <Tooltip title={assurance.datetime ? assurance.datetime : ''}>
            <Box>
              <ActionButton disableRipple={false} bg='blue' disabled={!!assurance.datetime} onClick={() => onActionButtonClick('assurance')} startIcon={
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.28742 15.956H4.98448V3.27558H10.7646V7.23821L14.8933 7.21444V9.59202L16.5448 10.2807V6.42191L14.8933 4.83686L13.2418 3.2518L11.5904 1.66675H4.98448C4.54648 1.66675 4.12642 1.83374 3.81671 2.131C3.507 2.42826 3.33301 2.83142 3.33301 3.2518V15.9322C3.33301 16.3526 3.507 16.7558 3.81671 17.053C4.12642 17.3503 4.54648 17.5173 4.98448 17.5173H10.1715L8.28742 15.956ZM13.2425 9.61576H6.63657V11.2008H13.2425V9.61576ZM10.7652 12.7859V13.5546L9.91474 14.3709H6.63657V12.7859H10.7652Z" fill='CurrentColor' />
                  <path d="M8.43164 14.1081L7.50128 13.7414L7.35679 14.1081L7.50128 14.4747L8.43164 14.1081ZM18.4316 14.1081L19.362 14.4747L19.5065 14.1081L19.362 13.7414L18.4316 14.1081ZM13.4316 9.69897C10.7329 9.69897 8.43256 11.3782 7.50128 13.7414L9.36201 14.4747C10.0034 12.847 11.585 11.699 13.4316 11.699V9.69897ZM7.50128 14.4747C8.43256 16.8379 10.7329 18.5172 13.4316 18.5172V16.5172C11.585 16.5172 10.0034 15.3691 9.36201 13.7414L7.50128 14.4747ZM13.4316 18.5172C16.1304 18.5172 18.4307 16.8379 19.362 14.4747L17.5013 13.7414C16.8598 15.3691 15.2783 16.5172 13.4316 16.5172V18.5172ZM19.362 13.7414C18.4307 11.3782 16.1304 9.69897 13.4316 9.69897V11.699C15.2783 11.699 16.8598 12.847 17.5013 14.4747L19.362 13.7414Z" fill="#E4EDF8" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M13.4316 10.699C11.1589 10.699 9.218 12.1126 8.43164 14.1081C9.218 16.1035 11.1589 17.5172 13.4316 17.5172C15.7044 17.5172 17.6453 16.1035 18.4316 14.1081C17.6453 12.1126 15.7044 10.699 13.4316 10.699ZM13.4314 16.3808C12.1769 16.3808 11.1587 15.3626 11.1587 14.1081C11.1587 12.8535 12.1769 11.8353 13.4314 11.8353C14.686 11.8353 15.7041 12.8535 15.7041 14.1081C15.7041 15.3626 14.686 16.3808 13.4314 16.3808ZM12.0677 14.1081C12.0677 13.3536 12.6768 12.7445 13.4313 12.7445C14.1859 12.7445 14.795 13.3536 14.795 14.1081C14.795 14.8626 14.1859 15.4717 13.4313 15.4717C12.6768 15.4717 12.0677 14.8626 12.0677 14.1081Z" fill="CurrentColor" />
                </svg>
              }>
                Визировать
              </ActionButton>
            </Box>
          </Tooltip>
        }

        {buttonAvailable &&
          <ActionButton disableRipple={false} bg='red' startIcon={<AddCommentIcon fontSize='medium' color='error' />}
            onClick={navigateToRemarks}>
            Замечание
          </ActionButton>
        }

        <SubmitButton startIcon={<CheckIcon fontSize='medium' />}
          disabled={disabled} onClick={(e) => setAnchorEl(e.currentTarget)}>
          {disabled ? 'Этап завершен' : 'Завершить этап'}
        </SubmitButton>
        <StyledMenu
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={() => setAnchorEl(null)}
        >
          <StyledMenuItem type='agreed' onClick={() => handleSelect('agreed')}>Согласовано</StyledMenuItem>
          <StyledMenuItem type='rejected' onClick={() => handleSelect('rejected')}>Отклонено</StyledMenuItem>
        </StyledMenu>
      </Stack>
      <ConfirmDialog />
    </>
  )
}