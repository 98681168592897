import { pdfjs, Document, } from "react-pdf";
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import './LibraryStyles.css';
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Wrapper, ToolBar, ActionButton, ScrollContainer, RightBlockToolbar } from "./PragmaPdfViewer.styles";
import { DocumentInitParameters } from "pdfjs-dist/types/src/display/api";
import { AlignmentType, ZoomComponent } from "./components/ZoomComponent";
import { PageNumberComponent } from "./components/PageNumberComponent";
import { Divider, Stack, Typography } from "@mui/material";
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import Rotate90DegreesCcwIcon from '@mui/icons-material/Rotate90DegreesCcw';
import Rotate90DegreesCwOutlinedIcon from '@mui/icons-material/Rotate90DegreesCwOutlined';
import AddCommentIcon from '@mui/icons-material/AddComment';
import { Loader } from "./components/Loader/Loader";
import { Tooltip } from "./components/Tooltip";
import { AnnotationType, PageData, PragmaPdfViewerProps } from "./PragmaPdfViewer.types";
import { HandToolComponent } from "./components/HandToolComponent";
import { VisibilityRemarksIcon } from "./icons/VisibilityRemarksIcon";
import { MenuToolBar } from "./components/MenuToolBar/MenuToolBar";
import { NotesToolbar } from "./components/NotesToolbar";
import { LazyPage } from "./components/LazyPage";
import { useAppDispatch, useTypedSelector } from "@store/store";
import { getClickHandlerSelector, remarkFormModeSelector, remarkPageSelector, selectedRemarkIdSelector } from "@store/slices/remarks/selectors/remarks.selectors";
import { getClickHandler, selectAnnotationId, setMarkCount, setSelectedRemarkId } from "@store/slices/remarks/remarks";

pdfjs.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.4.168/pdf.worker.min.mjs';

const initialZoom = 100

const options: Omit<DocumentInitParameters, "data" | "range" | "url"> = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
  rangeChunkSize: 200536,
  disableAutoFetch: true,
  disableStream: true
}

export const PragmaPdfViewer = ({ file, fileName, isOpenRemarkForm, actionButtonRemark, remarkAnnotations, pageOfSelectedRemark }: PragmaPdfViewerProps) => {
  const dispatch = useAppDispatch()
  const memoizedFile = useMemo(() => file, [file])
  const [zoom, setZoom] = useState<number>(initialZoom)
  const [numPages, setNumPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [rotate, setRotate] = useState(0)
  const [widthAlignment, setWidthAlignment] = useState<AlignmentType>('height')
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [isScrolling, setIsScrolling] = useState(false)
  const [handTool, setHandTool] = useState(false)
  const [defaultHeight, setDefaultHeight] = useState(window.innerHeight - 174)
  const [selectedNoteMode, setSelectedNoteMode] = useState<AnnotationType | null>(null)
  const [pageData, setPageData] = useState<PageData[]>([])
  const [isShowAllMarks, setIsShowAllMarks] = useState(false)
  const [selectedAnnotationId, setSelectedAnnotationId] = useState<number | null>(null)
  const rootContainerRef = useRef<HTMLDivElement>(null)
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const scrollTimeoutRef = useRef<NodeJS.Timeout | null>(null)
  const cachedPageNumber = useRef<number | null>(null)
  const remarkFormMode = useTypedSelector(remarkFormModeSelector)
  const remarkPage = useTypedSelector(remarkPageSelector)
  const selectedRemarkId = useTypedSelector(selectedRemarkIdSelector)
  //const clickHandler = useTypedSelector(getClickHandlerSelector)
  const scale = zoom / 100
  const isDisabledShowAllMarks = remarkFormMode === 'edit'
  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages)
    setTimeout(() => {
      if (scrollContainerRef.current) {
        scrollToPage(1, true)
      }
    }, 0)
    setTimeout(() => {
      if (scrollContainerRef.current && pageOfSelectedRemark && pageOfSelectedRemark.page !== currentPage) {
        scrollToPage(pageOfSelectedRemark.page, true)
      }
    }, 1000)
  }

  const scrollToPage = useCallback((page: number, force?: boolean) => {
    if (!scrollContainerRef.current) return
    const pageElement: HTMLDivElement | null = document.querySelector(`[data-page='${page - 1}']`)
    if (pageElement) {
      setIsScrolling(true)
      const { offsetTop } = pageElement
      scrollContainerRef.current.scrollTo({
        top: offsetTop,
        behavior: !force ? 'smooth' : 'instant' as ScrollBehavior,
      })

      setCurrentPage(page)
      setTimeout(() => {
        setIsScrolling(false)
      }, 1000)
    }
  }, [])

  const changeZoom = useCallback((value: number) => {
    setZoom(value)
  }, [])

  const onHandToolChange = (value: boolean) => {
    setHandTool(value)
    if (value) {
      setSelectedNoteMode(null)
    }
  }

  const handleShowAllMarks = () => {
    if (isShowAllMarks) {
      dispatch(setSelectedRemarkId(null))
    }
    setIsShowAllMarks(!isShowAllMarks)
  }

  const onSelectNoteMode = (value: AnnotationType | null) => {
    remarkPage && setSelectedNoteMode(value)
    handTool && setHandTool(false)
  }

  const changeAlignMent = useCallback((value: AlignmentType) => {
    if (value === widthAlignment) return
    cachedPageNumber.current = currentPage
    setWidthAlignment(value)
    setPageData([])
    setZoom(initialZoom)
  }, [currentPage, widthAlignment])

  const changeRotate = (rotatedValue: number) => {
    cachedPageNumber.current = currentPage
    let rotateCurrent = rotate === 0 ? 360 : rotate
    rotateCurrent = (rotateCurrent - rotatedValue) % 360
    if (rotateCurrent < 0) {
      rotateCurrent += 360
    }
    setPageData([])
    setRotate(rotateCurrent)
  }

  const onActionButtonRemark = () => {
    actionButtonRemark!(currentPage, numPages)
  }

  //---Полноэкранный режим---\\

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      rootContainerRef.current?.requestFullscreen().catch(err => console.error('Полноэкранный режим не поддерживается'))
    } else {
      document.exitFullscreen()
    }
  }

  useEffect(() => {
    const onFullScreenChange = () => {
      setIsFullScreen(Boolean(document.fullscreenElement))
    }
    document.addEventListener('fullscreenchange', onFullScreenChange)
    return () => {
      document.removeEventListener('fullscreenchange', onFullScreenChange)
    }
  }, [])

  useEffect(() => {
    if (document.fullscreenElement && isFullScreen) {
      setDefaultHeight(document.fullscreenElement.clientHeight - 86)
    } else {
      setDefaultHeight(window.innerHeight - 174)
    }
    setZoom(initialZoom)
  }, [isFullScreen])

  //---Управление скроллом---\\

  useEffect(() => {
    const page = cachedPageNumber.current
    if (page) {
      scrollToPage(page, true)
      cachedPageNumber.current = null
    }
  }, [cachedPageNumber.current])

  const handleScrollEnd = useCallback(() => {
    if (!scrollContainerRef.current || isScrolling) return

    const { scrollTop, clientHeight } = scrollContainerRef.current
    const scrollMiddle = scrollTop + clientHeight / 2

    let newCurrentPage = currentPage

    for (let pageIndex = 0; pageIndex < numPages; pageIndex++) {
      const pageElement: HTMLDivElement | null = document.querySelector(`[data-page='${pageIndex}']`)

      if (pageElement) {
        const { offsetTop, clientHeight } = pageElement
        const pageMiddle = offsetTop + clientHeight / 2

        if (Math.abs(pageMiddle - scrollMiddle) < clientHeight / 2) {
          newCurrentPage = pageIndex + 1
          break
        }
      }
    }

    if (newCurrentPage !== currentPage) {
      setCurrentPage(newCurrentPage)
    }
  }, [numPages, currentPage, isScrolling])

  const blockPageScroll = useCallback(() => {
    const scrollContainer = scrollContainerRef.current
    if (!scrollContainer) return
    if (isOpenRemarkForm && remarkPage) {
      const pageElement: HTMLDivElement | null = document.querySelector(`[data-page='${remarkPage - 1}']`)
      if (pageElement) {
        const { offsetTop, clientHeight } = pageElement
        const { scrollTop } = scrollContainer
        const maxScroll = offsetTop + clientHeight - scrollContainer.clientHeight
        //const minZoom = scrollContainer.clientHeight / clientHeight
        if (scrollTop < offsetTop) {
          scrollContainer.scrollTo({ top: offsetTop })
        } else if (scrollTop > maxScroll) {
          scrollContainer.scrollTo({ top: maxScroll })
        }
      }
    }
  }, [isOpenRemarkForm, remarkPage])


  const handleScroll = useCallback(() => {
    blockPageScroll()
    if (scrollTimeoutRef.current) {
      clearTimeout(scrollTimeoutRef.current)
    }

    scrollTimeoutRef.current = setTimeout(handleScrollEnd, 100)
  }, [blockPageScroll, handleScrollEnd])

  const onSelectAnnotationId = (id: number) => {
    setSelectedAnnotationId(id)
    dispatch(selectAnnotationId(id))
  }

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current
    if (!scrollContainer) return

    scrollContainer.addEventListener('scroll', handleScroll)
    return () => {
      scrollContainer.removeEventListener('scroll', handleScroll)
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current)
      }
    }
  }, [handleScroll, file, scrollContainerRef])

  useEffect(() => {
    if (!isOpenRemarkForm) {
      setSelectedNoteMode(null)
    }
  }, [isOpenRemarkForm])

  useEffect(() => {
    if (pageOfSelectedRemark && pageOfSelectedRemark.page !== currentPage) {
      scrollToPage(pageOfSelectedRemark.page)
    }
    /* if (clickHandler) {
      dispatch(getClickHandler(false))
    } */
  }, [pageOfSelectedRemark/* , clickHandler */])

  //---Скролл на выбранную страницу при создании/редактировании замечания
  // и скрытие скроллбара если высота страницы не превышает область просмотра ---\\

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current
    if (!scrollContainer) return
    if (isOpenRemarkForm) {
      if (remarkPage && remarkPage <= numPages) {
        const pageElement: HTMLDivElement | null = document.querySelector(`[data-page='${remarkPage - 1}']`)
        if (pageElement) {
          scrollToPage(remarkPage, true)
          const pageHeight = pageElement.clientHeight
          const containerHeight = scrollContainer.clientHeight
          pageHeight <= containerHeight ? scrollContainer.style.overflowY = 'hidden' : scrollContainer.style.overflowY = 'auto'
        }
      } else {
        setSelectedNoteMode(null)
        dispatch(setMarkCount(null))
        scrollContainer.style.overflowY = 'auto'
      }
    } else {
      scrollContainer.style.overflowY = 'auto'
    }
  }, [remarkPage, isOpenRemarkForm, scale, widthAlignment, rotate])

  useEffect(() => {
    if (remarkFormMode === 'edit') {
      setIsShowAllMarks(false)
    }
  }, [remarkFormMode])

  useEffect(() => {
    const existingAnnotation = remarkAnnotations.find(({ remarkId }) => remarkId === selectedRemarkId)?.annotations.find(a => a.id === selectedAnnotationId)
    if (isOpenRemarkForm || !isShowAllMarks || !existingAnnotation) {
      setSelectedAnnotationId(null)
      dispatch(selectAnnotationId(null))
    }
  }, [isOpenRemarkForm, isShowAllMarks, selectedRemarkId])

  useEffect(() => {
    setCurrentPage(1)
    setZoom(initialZoom)
    setHandTool(false)
    setRotate(0)
    setPageData([])
    setWidthAlignment('height')
  }, [file])

  return (
    <Wrapper spacing={1} ref={rootContainerRef}>
      <ToolBar>
        <Stack direction='row' spacing={1}>
          <Tooltip title='На весь экран'>
            <ActionButton onClick={toggleFullScreen} iconcolor={isFullScreen ? 'blue' : 'gray'}>
              <AspectRatioIcon />
            </ActionButton>
          </Tooltip>
          <HandToolComponent
            scrollContainerRef={scrollContainerRef}
            handTool={handTool}
            setHandTool={onHandToolChange}
          />
          <Tooltip title='Показать пометки'>
            <ActionButton onClick={handleShowAllMarks} disabled={isDisabledShowAllMarks}>
              {VisibilityRemarksIcon(isShowAllMarks)}
            </ActionButton>
          </Tooltip>
          <Tooltip title='Повернуть против часовой стрелки'>
            <ActionButton onClick={() => changeRotate(90)} iconcolor='gray'>
              <Rotate90DegreesCcwIcon />
            </ActionButton>
          </Tooltip>
          <Tooltip title='Повернуть по часовой стрелке'>
            <ActionButton onClick={() => changeRotate(-90)} iconcolor='gray'>
              <Rotate90DegreesCwOutlinedIcon />
            </ActionButton>
          </Tooltip>
        </Stack>

        <Stack direction='row' alignItems='center' spacing={4}>
          <Stack direction='row' alignItems='center' spacing={2}>
            {fileName &&
              <Typography fontWeight={600} fontSize={16}>{fileName}</Typography>}
            <PageNumberComponent
              currentPage={currentPage}
              numPages={numPages}
              disabled={isOpenRemarkForm && !!remarkPage}
              onPageChange={scrollToPage} />
          </Stack>
          <ZoomComponent
            zoom={zoom}
            widthAlignment={widthAlignment}
            rootContainerRef={rootContainerRef}
            onChangeZoom={changeZoom}
            onChangeAlignment={changeAlignMent} />
        </Stack>
        {isOpenRemarkForm ? (
          <NotesToolbar
            disabled={!remarkPage || remarkPage > numPages || isFullScreen}
            selectMode={onSelectNoteMode}
            selectedMode={selectedNoteMode} />
        ) : (
          <RightBlockToolbar direction='row' spacing={2.5}>
            <MenuToolBar file={memoizedFile} />
            {actionButtonRemark &&
              <Stack direction='row' spacing={2.5}>
                <Divider orientation="vertical" flexItem sx={{ borderColor: '#0044b41a', borderRightWidth: '2px' }} />
                <Tooltip title='Замечание'>
                  <ActionButton disableRipple onClick={onActionButtonRemark} disabled={isFullScreen}>
                    <AddCommentIcon fontSize="medium" />
                  </ActionButton>
                </Tooltip>
              </Stack>
            }
          </RightBlockToolbar>)
        }
      </ToolBar>

      <Document
        file={memoizedFile}
        options={options}
        onLoadSuccess={onDocumentLoadSuccess}
        rotate={rotate}
        loading={<Loader height={defaultHeight - 18} />}
        externalLinkTarget="_blank"
      >
        <ScrollContainer ref={scrollContainerRef}>
          {Array.from({ length: numPages }, (_, index) => (
            <LazyPage
              key={`${file}-${index}`}
              pageIndex={index}
              scale={scale}
              currentPage={currentPage}
              defaultHeight={defaultHeight}
              widthAlignment={widthAlignment}
              scrollContainerRef={scrollContainerRef}
              rotate={rotate}
              handTool={handTool}
              pageData={pageData}
              isShowAllMarks={isShowAllMarks}
              selectedNoteMode={selectedNoteMode}
              isOpenRemarkForm={isOpenRemarkForm}
              incomingAnnotations={remarkAnnotations}
              setPageData={(data) => setPageData((prevData) => [...prevData, data])}
              selectAnnotationId={onSelectAnnotationId}
              selectedAnnotationId={selectedAnnotationId}
            />
          ))}
        </ScrollContainer>
      </Document>
    </Wrapper>
  )
}

