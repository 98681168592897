import { AgreementOverviewStage, OverviewColorStatus, StageMember } from "@api/agreementOverview/types"

export interface AgreementOverviewSchemaProps {
  onClickInnermapStage: (members: StageMember[]) => void
  stages: AgreementOverviewStage[]
}

export type AgreementOverviewColor = 'green' | 'red' | 'gray' | 'blue'
export interface RoadmapStatusToColor extends Record<OverviewColorStatus, AgreementOverviewColor> { }
export const roadmapStatusToColor: RoadmapStatusToColor = {
  'negative': 'red',
  'positive': 'green',
  'unknown': 'gray'
}

