import { Stack, Typography } from "@mui/material";
import styled from "styled-components";

export const PageSection = styled(Stack)`
  flex-direction: row;
  width: 100%;
  border-radius: 10px;
  background-color: ${props => props.theme.palette.bg.white};
  
  & > div {
    &:not(:last-of-type) {
      border-right: 1px solid #0000001A;
    }
  }
`
export const SchemaSeparator = styled.span`
  position: absolute;
  width: 100%;
  top: 82px;
  left: 0;
  border-bottom: 1px solid #0000001A;
`
export const BlockWrapper = styled(Stack)`
  padding: 5px 24px;
  justify-content: space-evenly;
  height: 100%;
`

export const TitleText = styled(Typography)`
  font-size: 12px;
  font-weight: 500;
  color: ${props => props.theme.palette.secondary.main};
`
export const TitleNormalText = styled(TitleText)`
  font-weight: 400;
`

export const OverflowingText = styled(Typography)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
`
export const UserBlock = styled(Stack)`
  box-sizing: content-box;
  text-align: start;
  justify-content: center;
  & > div {
    height: 50%;
    padding: 8px 16px;

    :not(:last-of-type) {
      border-bottom: 1px solid #0000001A;
    }
  }
`