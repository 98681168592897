import { Divider, Stack, Typography } from "@mui/material"
import { DocumentLabel, RemarkCounterBlock, TomInteractionBlockProps } from "."
import { Link, useNavigate, useParams } from "react-router-dom"
import useQuery from "@hooks/useQuery"

export const TomInteractionBlock = ({ remarks }: TomInteractionBlockProps) => {
  const { projectId, tomId } = useParams()
  const queryHandler = useQuery()
  const navigate = useNavigate()
  const tomType = queryHandler.get('type')

  const navigateToRemarks = () => {
    navigate(`/project/${projectId}/remarks?phase=${tomType}`)
  }

  return (
    <Stack direction='row' spacing={2} alignItems='center'>
      <DocumentLabel>PDF</DocumentLabel>
      <Link to={`/project/${projectId}/tom/${tomId}?tab=docs`}>
        <Typography variant="subtitle2" fontWeight={400}>Титул 1-АЗ1</Typography>
      </Link>
      <RemarkCounterBlock onClick={navigateToRemarks}>
        <Typography variant="body2">Замечания: {remarks.totalOpened}</Typography>
        <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
        <Typography variant="body2">Ответы: {remarks.totalClosed}</Typography>
      </RemarkCounterBlock>
    </Stack>
  )
}